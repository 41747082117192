@use '../utils' as *;

/*----------------------------------------*/
/*  24. team CSS START
/*----------------------------------------*/
.team{
    &__plr{
        padding: 0 200px;
        @media #{$xxxl}{
            padding:0 100px;
        }
        @media #{$xxl}{
            padding:0 100px;
        }
        @media #{$xl,$lg,$md}{
            padding: 0 30px;
        }
        @media #{$xs}{
            padding: 0;
        } 
    }
    &__space{
        padding-top: 345px;
        padding-bottom: 95px;
    }
    &__bg{
        position: relative;
        z-index: 2;
        &::after{
            position: absolute;
            content: '';
            background-color: var(--it-common-black);
            bottom:0;
            left: 0;
            width: 100%;
            height: 46%;
            z-index: -1;
            @media #{$lg}{
                display: none;
            }
            @media #{$md}{
                display: none;
            }
            @media #{$xs}{
                display: none;
            }

        }
    }
    &__grey-bg {
        position: relative;
        &::before{
            position: absolute;
            content: '';
            background-color: var(--it-grey-1);
            top:0;
            left: 0;
            width: 100%;
            height: 54%;
            z-index: -1;
            @media #{$lg}{
                height: 100%;
            }

        }
    }
    &__item {
        padding-bottom: 50px;
        &:hover{
            & .team__main-thumb{
                &::after{
                    height: 100%;
                    opacity: 1;
                    visibility: visible;
                }
            }
            & .team__social-box{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__main-thumb{
        & img{
            width: 100%;
        }
        position: relative;
        &::after{
            content: '';
            position: absolute;
            background-color:  rgba(41, 41, 48, 0.6);
            bottom:0;
            left: 0;
            height: 0;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: .4s;
        }
    }
    &__social-box{
        z-index: 2;
        position: absolute;
        content: '';
        top: 45%;
        left: 0;
        right: 0;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: .5s;

        & a{
            height: 40px;
            width: 40px;
            border: 1px solid var(--it-common-white);
            border-radius: 50%;
            line-height: 40px;
            text-align: center;
            display: inline-block;
            margin: 0 5px;
            transition: .3s;
            & i{
                font-size: 17px;
                color: var(--it-common-white);
                transition: .3s;
            }
            &:hover{
                background-color: var(--it-theme-1);
                border: 1px solid transparent;
                & i{
                    color: var(--it-common-black);
                }
            }
        }
    }
    &__author-info{
        padding: 30px;
        background-color: var(--it-common-white);
        @media #{$lg,$md}{
            font-size: 14px;
        }
    }
    &__author-name{
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0;
        padding-bottom: 5px;
        transition: .3s;
        @media #{$lg,$md}{
            font-size: 18px;
        }
        @media #{$xs}{
            font-size: 18px;
        }
        @media #{$sm}{
            font-size: 17px;
        }
        &:hover{
            color: var(--it-common-blue-4);
        }
    }
    &__top-text {
        & p{
            padding-right: 106px;
            @media #{$xxxl}{          
                padding-right: 85px;
            }
            @media #{$xxl}{          
                padding-right:7px;
            }
            @media #{$xl}{          
                padding-right:0;
            }
            @media #{$lg,$md,$xs}{          
                padding-right:0;
                font-size: 15px;
            }
            @media #{$sm}{          
                padding-right:0;
            }
        }
    }
    &__shape-1{
        position: absolute;
        left: 1%;
        top: 23%;
    }
    &__space-2{
        padding-top: 250px;
        margin-top: -220px;
        padding-bottom: 50px;
    }
}
