@use '../utils' as *;

/*----------------------------------------*/
/*  26. video CSS START
/*----------------------------------------*/
.video{
    &__plr{
        padding: 130px 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media #{$xs}{                
            padding: 130px 0;
        }
        @media #{$xxs}{                
            padding: 120px 0;
        }
    }
    &__content{
        & p{
            color: var(--it-common-white);
            padding-right: 80px;
            padding-bottom: 13px;
            @media #{$lg,$md}{   
                padding-right: 0;             
                & br{
                    display: none;
                }
            }
            @media only screen and (min-width: 440px) and (max-width:575px){            
                & br{
                    display: none;
                }
            }
            @media #{$xs}{                
                padding-right: 0;
            }
        }
    }
    &__icon{
        @media #{$lg,$md,$xs}{      
            margin-right: 0;
        }
        & a{
            font-size: 18px;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background-color: var(--it-common-white);
            line-height: 63px;
            padding-left: 6px;
            display: inline-block;
            text-align: center;
            color: #404040;
            position: relative;
            z-index: 1;
            &::after{
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                top:50%;
                left: 50%;
                transform: translate(-50%,-50%);
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.551);
                animation: borderanimate2 2s linear infinite;
                z-index: -1;
            }

        }
    }
    &__button {
        @media #{$xs}{      
            margin-bottom: 50px;
        }
        @media #{$xxs}{      
            margin-bottom: 0;
        }
      }
}
.video-2{
    &__bg{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        margin:0 200px;
        @media #{$xxxl}{
            margin:0 115px;
        }
        @media #{$xxl}{
            margin:0 115px;
        }
        @media #{$xl,$lg,$md}{
            margin: 0 45px;
        }
        @media #{$xs}{
            margin: 0;
        } 
    }
    &__space {
        padding: 100px 0;
        margin-bottom: -250px;
        position: relative;
        z-index: 9;
        @media #{$md}{                
            padding: 65px 0;
        }
    }
    &__icon{
        & span{
            height: 60px;
            width: 60px;
            border-radius: 50%;
            border: 3px solid var(--it-common-white);
            line-height: 58px;
            text-align: center;
            display: inline-block;
            & i{
                font-size: 17px;
                color: var(--it-common-white);
                padding-left: 4px;
            }
        }

    }
    &__btn{
        margin-right: 45px;
        @media #{$xs}{
            margin-right: 20px;
        } 
    }
}
.funfact{
    &__space{
        padding: 0 200px;
        @media #{$xxxl}{
            padding:0 100px;
        }
        @media #{$xxl}{
            padding:0 100px;
        }
        @media #{$xl,$lg,$md}{
            padding: 0 30px;
        }
        @media #{$xs}{
            padding: 0;
        }     
    }
    &__bg{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 125px 80px;
        position: relative;
        border-radius: 10px;
        @media #{$lg}{
            padding: 70px 30px;
        } 
        @media #{$md}{
            padding: 60px 30px;
        } 
        @media #{$xs}{
            padding: 90px 20px;
        } 
    }
    &__shape-1{
        position: absolute;
        bottom: 0;
        right: 20%;
        @media #{$xxl}{
            right: 5%;
        } 
        @media #{$xl}{
            right: 3%;
        } 
        @media #{$lg}{
            right: -12%;
        } 
        & img{
            @media #{$xxl}{
                max-width: 75%;
            }  
            @media #{$xl}{
                max-width: 73%;
            }  
            @media #{$lg}{
                max-width: 60%;
            }  
            @media #{$md}{
                max-width: 60%;
            }  
        }
    }
    &__content{
        @media #{$xs}{
            margin-bottom:0;
        } 
        @media #{$sm}{
            margin-bottom:50px;
        } 
    }
    &__text{
        padding-bottom: 40px;
    }
    &__icon{
        & a{
        height: 65px;
        width: 65px;
        line-height: 68px;
        border-radius: 50%;
        background-color: var(--it-theme-1);
        display: inline-block;
        text-align: center;
        position:relative;
        z-index: 2;
        &::after{
            height:100%;
            width:100%;
            border-radius: 50%;
            background-color: rgba(243, 215, 111, 0.588);
            position: absolute;
            content: "";
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            z-index: -1;
            transform: scale(1.3);
            animation: borderanimate2 2s linear infinite;
        }
        & span{
            font-size: 20px;
            color: var(--it-common-black);
            padding-left: 9px;
        }
        }
    }
}
.funfact-2{
    &__space{
        padding: 0 200px;
        margin-top: 110px;
        @media #{$xxxl}{
            padding:0 100px;
        }
        @media #{$xxl}{
            padding:0 100px;
        }
        @media #{$xl,$lg,$md}{
            padding: 0 30px;
        }
        @media #{$xs}{
            padding: 0;
        }     
    }
    &__bg{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 34px 80px;
        position: relative;
        border-radius: 30px;
        @media #{$lg}{
            padding: 30px 30px;
        } 
        @media #{$md}{
            padding: 40px 0px;
        } 
        @media #{$xs}{
            padding: 34px 0px;
        } 
    }
    &__content{
        @media #{$xs}{
            margin-bottom: 50px;
        } 
    }
    &__text{
        padding-bottom: 40px;
        & .section-title{
            @media #{$md}{
                font-size: 34px;
            } 
        }
    }
    &__main-img {
        padding-left: 20px;
        @media #{$xs}{
            padding-left: 0;
        } 
    }
    &__button{
        & .border-btn-3{
            @media #{$md}{
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
            }
        }
    }
}


/*Modal Video Section CSS*/
.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: modal-video;
    transition: opacity .3s ease-out;
    .modal-video-body {
        max-width: 960px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 10px;
        display: table;
        box-sizing: border-box;
        .modal-video-inner {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            height: 100%;
            .modal-video-movie-wrap {
                width: 100%;
                height: 0;
                position: relative;
                padding-bottom: 56.25%;
                background-color: #333;
                animation-timing-function: ease-out;
                animation-duration: .3s;
                animation-name: modal-video-inner;
                transform: translate(0);
                transition: transform .3s ease-out;
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                .modal-video-close-btn {
                    position: absolute;
                    z-index: 2;
                    top: -45px;
                    right: 0;
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    overflow: hidden;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        top: 50%;
                        left: 0;
                        background: #fff;
                        border-radius: 5px;
                        margin-top: -6px;
                    }
                    &:before {
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            } 
        }
    }
}


// Masonary

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }