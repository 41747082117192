@use '../utils' as *;

/*----------------------------------------*/
/*  17. newsletter CSS START
/*----------------------------------------*/
.newsletter{
    &__plr{
        padding: 0 200px; 
        @media #{$xxxl}{
            padding:0 100px;
        }
        @media #{$xxl}{
            padding:0 100px;
        }
        @media #{$xl,$lg,$md}{
            padding: 0 30px;
        }
        @media #{$xs}{
            padding: 0;
        }
    }
    &__bg{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 492px;
        padding-top: 300px;
        margin-top: -210px;
        @media #{$lg}{
            padding-top: 240px;
        }
        @media #{$md}{
            padding-top: 240px;
            margin-top: -180px;
        }
        @media #{$xs}{
            padding-top: 216px;
            margin-top: -180px;
        }
    }
    &__icon{
        padding-right: 45px;
        flex: 0 0 auto;
        @media #{$xl}{
            padding-right: 35px;
        }
        @media #{$xs}{
            padding-right: 15px;
        }

    }
    &__input-box{
        position: relative;
        margin-left: 120px;
        @media #{$xxl,$xl}{
            margin-left: 60px;
        }
        @media #{$lg}{
            margin-left: 0;
            margin-top: 20px;
        }
        @media #{$md}{
            margin-left: 0;
            margin-top: 20px;
        }
        @media #{$xs}{
            margin-left: 0;
            margin-top: 20px;
        }
        & input{
            height: 80px;
            line-height: 80px;
            width: 100%;
            background-color: var(--it-common-white);
            border-radius: 60px;
            border: none;
            box-shadow: 0px 0px 70px 0px rgba(0, 80, 159, 0.06);
            padding-left: 40px;
            padding-right: 220px;
            @media #{$xs}{
                height: 40px;
                line-height: 40px;
                padding-right: 140px;
            }
            @media #{$sm}{
                height: 65px;
                line-height: 65px;
            }
        }
        & button {
            position: absolute;
            top: 0;
            right: 0;
            &.subscribe-btn-2{
                @media #{$xs}{
                    height: 40px;
                    line-height: 40px;
                    padding: 0 20px;
                }
                @media #{$sm}{
                    height: 65px;
                    line-height: 65px;
                    padding:0 40px;
                    & span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &__item{
        @media #{$xs}{
            flex-wrap: wrap;
        }
        @media #{$sm}{
            flex-wrap: nowrap;
        }
    }

}
  
.newsletter-2{
    &__plr{
        padding: 0 200px; 
        @media #{$xxxl}{
            padding:0 100px;
        }
        @media #{$xxl}{
            padding:0 100px;
        }
        @media #{$xl,$lg,$md}{
            padding: 0 30px;
        }
        @media #{$xs}{
            padding: 0;
        }
    }
    &__space{
        padding-top: 85px;
        padding-bottom: 85px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__icon{
        padding-right: 45px;
        @media #{$lg}{
            padding-right: 30px;
        }
        @media #{$md}{
            padding-right: 15px;
        }
        @media #{$xs}{
            padding-right: 15px;
        }
    }
    &__input-box{
        position: relative;
        margin-left: 110px;
        @media #{$lg}{
            margin-left: 70px;
        }
        @media #{$md}{
            margin-left:0;
        }
        @media #{$xs}{
            margin-left:0;
        }
        & input{
            border: none;
            width: 100%;
            height: 80px;
            line-height: 80px;
            background-color: var(--it-common-white);
            border-radius: 60px;
            font-size: 16px;
            font-weight: 400;
            padding-left: 45px;
            padding-right: 220px;            
	        box-shadow: 0px 0px 70px 0px rgba(0, 80, 159, 0.06);
            @media #{$lg}{
                height: 55px;
                line-height: 55px;
                border-radius: 60px;
                font-size: 14px;
                padding-left: 25px;
                padding-right: 160px;
            }
            @media #{$md}{
                height: 50px;
                line-height: 50px;
                border-radius: 60px;
                font-size: 14px;
                padding-left: 25px;
                padding-right: 160px;
            }
            @media #{$xs}{
                height: 50px;
                line-height: 50px;
                border-radius: 60px;
                font-size: 13px;
                padding-left: 25px;
                padding-right: 140px;
            }
            @media #{$sm}{
                font-size: 15px;
                padding-left: 40px;
            }
        }
        & button{
            position: absolute;
            top: 0;
            right: 0;
            @media #{$xxxl}{
                bottom: 8%;
                right: -5px;
            }
        }
    }
    &__item {
        @media #{$xs}{
            margin-bottom: 30px;
        }
    }
}
.newsletter-2{
    &__text{
        @media #{$xl}{
            & .section-title{
                font-size: 34px;
            }
        }
        @media #{$lg}{
            & .section-title{
                font-size: 28px;
            }
        }
        @media #{$md}{
            & .section-title{
                font-size: 26px;
            }
        }
        @media #{$xs}{
            & .section-title{
                font-size: 26px;
            }
        }
        @media #{$xs}{
            & .section-title{
                font-size: 25px;
            }
        }
    }
}